.home-teaser {
  position: relative;
  margin-top: -120px;

  &__animation {
    display: block;
    position: relative;
  }

  &__image {
    width: 100%;
    min-height: 400px;
    object-fit: cover;
    opacity: 0.8;
  }

  &__controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 900px) {
      font-size: small;
    }
    @media (max-width: 700px) {
      font-size: x-small;
    }
    @media (max-width: 600px) {
      flex-direction: column-reverse;
    }
  }

  &__heading {
    width: 22em;
    max-width: 80%;
  }

  &__title {
    text-transform: uppercase;
    line-height: 1.1;
    margin: 0.3em 0;
  }

  &__subtitle {
    font-weight: 600;
    margin: 0.6em 0;
  }

  &__play {
    width: 15em;
  }
}
