body {
  margin: 0;
  background-color: #000;
  color: #e8e9ed;
  font-family: Poppins, Arial, sans-serif;
  font-size: 18px;
}

#root {
  position: relative;
  overflow-x: hidden;
}

.button {
  padding: 8px 30px;

  font-size: 12px;
  color: inherit;

  background-color: inherit;
  border: 1px solid;
  border-radius: 30px;

  cursor: pointer;
}

.cursor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;

  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  pointer-events: none;
}
