.services {
  &__content {
    max-width: 800px;

    @media (min-width: 1900px) {
      max-width: 1200px;
    }
  }

  &-list {
    list-style: none;
    margin: 0 0 3rem 0;
    padding: 0;

    li {
      margin: 0 0 0.5rem 0;
      padding: 0;
      font-size: 49px;
      font-weight: 800;
      color: #4f4f4f;
      // cursor: pointer;
      opacity: 0.6;
      transition: all 0.1s ease-in;

      &:hover {
        color: #fdbf12;
        opacity: 1;
      }
    }
  }

  &-subtitle {
    margin: 0;
    font-size: 18px;
    font-weight: 800;
    color: #4f4f4f;
    opacity: 0.6;
    text-transform: lowercase;
  }
}
