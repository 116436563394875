.menu {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  max-width: 800px;
  margin: 0;
  padding: 115px 0 30px;
  background-color: #fdbf12;
  transition: right 0.3s ease-in-out;

  &_visible {
    right: 0;
  }

  &__overlay {
    position: fixed;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);

    &_visible {
      right: 0;
    }
  }

  &__close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 30px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    @media (max-width: 460px) {
      padding: 20px;
    }
  }

  &__item {
    display: block;
    width: 340px;
    padding: 16px 35px;

    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    color: #000;
    text-decoration: none;
    transition: color 0.2s ease-in;

    &:hover {
      color: #0044cb;
    }

    &__active {
      background: #0044cb;
      color: #fff;
      cursor: default;

      &:hover {
        color: #fff;
      }
    }
  }

  &__links {
    padding: 100px 50px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 86px;
    height: 25px;
  }

  &__social-link {
    margin-left: 8px;
  }

  &__social-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.1s ease-in;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__sphere {
    position: absolute;
    z-index: -1;
    top: 0;
    right: -200px;
    transform: scale(0.8);
  }
}
