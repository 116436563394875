.about {
  &--description {
    margin: 0 auto 6rem;
    width: 50%;
    font-family: Oswald, sans-serif;
    font-size: 28px;
    font-weight: 200;
    text-align: center;

    b {
      font-weight: 600;
      color: #0044cb;
    }
  }

  &--heading {
    margin: 6rem 0 1rem 0;
    text-align: center;
  }

  &--clients {
    text-align: center;
    img {
      vertical-align: middle;
      margin: 2rem;
      width: 12vw;

      @media screen and (max-width: 1024px) {
        width: 124px;
      }
    }
  }

  &--team {
    text-align: center;
  }
  &--member {
    position: relative;
    display: inline-block;
    width: 390px;
    max-width: 40vw;
    vertical-align: top;

    @media (max-width: 580px) {
      max-width: 60vw;
    }

    &-photo {
      position: relative;
      margin: 10% 10% 0;
      width: 80%;
      border-radius: 50%;
    }
    &::after {
      position: absolute;
      content: ' ';
      top: 0;
      left: 0;
      width: 390px;
      max-width: 40vw;
      height: 390px;
      max-height: 40vw;
      transform: scale(0.81);

      background-image: url('../assets/member-cross-fire.svg');
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: 580px) {
        max-width: 60vw;
        max-height: 60vw;
      }
    }

    &-name {
      margin: 0.3em 0 0.1em 0;
      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 32px;

      @media (max-width: 800px) {
        font-size: 4vw;
      }
      @media (max-width: 580px) {
        font-size: 6vw;
      }
    }
    &-position {
      margin: 0.1em 0 1em;
      font-family: Poppins, sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: #0044cb;

      @media (max-width: 800px) {
        font-size: 2.6vw;
      }
      @media (max-width: 580px) {
        font-size: 4vw;
      }
    }
  }
}
