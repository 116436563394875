.section-title {
  position: relative;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;

  background: radial-gradient(rgba(27, 33, 48, 0.79), transparent 66%, transparent);

  @media (max-width: 800px) {
    min-height: 44vw;
  }

  &__sphere {
    position: absolute;
    height: 140%;
    z-index: -1;

    @media (max-width: 800px) {
      height: 60vw;
    }
  }

  &__sphere-image {
    position: absolute;
    height: 100%;
    transform: translateX(-50%);
  }

  &__bg {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 183px;
    min-width: 378px;
    background-image: url('../assets/cross-fire.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;

    @media (max-width: 800px) {
      min-height: 23vw;
      min-width: 47vw;
    }
  }

  &__text {
    position: relative;
    margin: 0;
    overflow: hidden;
    font-family: Oswald, sans-serif;
    font-weight: 200;

    padding-left: calc(min(2.5vw, 55px));
    top: -3vw;
    font-size: calc(min(7.5vw, 150px));
    letter-spacing: calc(min(2.5vw, 55px));
  }
  &__inner {
    position: relative;
    top: calc(min(2.5vw, 50px));
  }
}
