.showreel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  &__right {
    flex-direction: row-reverse;
  }

  &__image-container,
  &__image-border,
  &__image {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }
  &__image-container {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transform: translateX(-2rem);
  }
  &__right &__image-container {
    transform: translateX(2rem);
  }
  &__image-border {
    position: absolute;
    z-index: 2;
    top: 5px;
    left: 5px;
    width: 290px;
    height: 290px;
    box-shadow: 0 0 2px 20px rgba(0, 68, 203, 0.2), 0 0 2px 20px rgba(0, 68, 203, 0.2) inset;

    transition: all 0.2s ease-in;
  }
  &__image-container__hover &__image-border,
  &__image-container:hover &__image-border {
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    box-shadow: 0 0 2px 20px rgba(0, 68, 203, 0.5), 0 0 2px 20px rgba(0, 68, 203, 0.5) inset;
  }
  &__image {
    z-index: -1;
  }

  &__play-button {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    background: #fdbf12;
    border-radius: 40px;
  }

  &__right &__play-button {
    left: 0;
    right: auto;
  }

  &__content {
    width: 40%;
    min-width: 250px;
    padding: 1.5rem 0 3rem;
  }

  &__title {
    margin: 0.2rem 0 0.8rem;
    max-width: 20rem;
    font-family: Oswald, sans-serif;
    font-weight: 200;
    font-size: 48px;
    line-height: 0.9;
    letter-spacing: 5px;
  }

  &__tags {
    margin: 0;
  }
  &__tag {
    font-size: 10px;
    color: #1bff99;
    margin: 0 8px 0 0;

    &::before {
      content: '●';
      font-size: 16px;
      vertical-align: -1px;
      margin-right: 3px;
    }
  }

  &__description {
    margin: 1.6rem 0;
    font-size: 12px;
  }

  &__video-link {
    margin: 16px 0 0;
    font-size: 16px;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #fdbf12;
      }
    }

    &::after {
      content: '●';
      font-size: 24px;
      color: #fdbf12;
      vertical-align: -1px;
      margin-left: 3px;
    }
  }
}
