.project-page {
  padding: 10px 30px 50px;

  color: #1c1a21;
  background-color: #fdbf12;

  &__content {
    max-width: 800px;
    margin: 0 auto;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 15px;
  }

  &__title {
    margin: 0;
    font-size: 32px;
    font-weight: 800;
  }

  &__type {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }

  &__video {
    width: 100%;
    height: calc(min(86vw - 60px, 800px) * 0.5625);

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__description {
    font-size: 14px;
    line-height: 1.6;
    min-height: calc(max((22rem - 25vw), 6rem));
  }

  &__prev-link,
  &__next-link {
    display: inline-block;
    width: calc(50% - 20px);
    padding: 10px;

    font-size: 20px;
    font-weight: 500;
    color: #1c1a21;
  }
  &__prev-link {
    float: left;
  }
  &__next-link {
    float: right;
    text-align: right;
  }
}
