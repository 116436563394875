.project-tile {
  display: inline-block;
  width: calc(50% - 40px);
  padding: 20px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  transition: all 0.3s ease-in;

  &:nth-child(4n + 1),
  &:nth-child(4n + 2) {
    background-color: #1c1a21;
  }

  @media (max-width: 540px) {
    width: calc(100% - 40px);

    &:nth-child(4n + 1),
    &:nth-child(4n + 2) {
      background-color: transparent;
    }
    &:nth-child(even) {
      background-color: #1c1a21;
    }
  }

  &:hover {
    background-color: #fdbf12;
  }

  > span {
    display: block;
  }

  &__image {
    margin: 0 0 10px 0;
    display: flex;

    img {
      display: block;
      width: 100%;
      min-height: 140px;
      object-fit: cover;
      object-position: center;
    }
  }

  &__title {
    margin: 0 0 5px 0;
    font-size: 20px;
    font-weight: 800;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__type {
    font-size: 12px;
    color: #737373;
  }
}
