.player-overlay {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    position: relative;

    @media (max-width: 800px) {
      position: static;
    }
  }

  &__close {
    position: absolute;
    z-index: 2;
    right: -30px;
    top: -30px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    @media (max-width: 800px) {
      top: 10px;
      right: 10px;
    }
  }

  &__player {
    width: 80vw;
    height: calc(80vw * 0.5625);

    @media (max-width: 800px) {
      width: 100vw;
      height: calc(100vw * 0.5625);
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
