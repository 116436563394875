.page-layout {
  padding: 0 80px;

  @media (max-width: 1200px) {
    padding: 0 6.6%;
  }
  @media (max-width: 400px) {
    padding: 0 30px;
  }

  &__content {
    margin: 0 auto;
    max-width: 1200px;
  }

  &__center {
    margin: 2rem;
    text-align: center;
  }

  /* bars */
  &__left-bar,
  &__right-bar {
    position: fixed;
    top: 0;
    width: 80px;
    height: 100vh;
    height: -webkit-fill-available;

    @media (max-width: 1200px) {
      width: 6.6%;
    }
    @media (max-width: 400px) {
      width: 30px;
    }
  }
  &__left-bar {
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__right-bar {
    right: 0;
    background-color: #0044cb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  /* navigation */
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);

    padding: 30px;
  }
  &__navigation {
    @media (max-width: 850px) {
      display: none;
    }
  }
  &__nav-item {
    display: inline-block;
    padding: 8px 0;
    margin-right: 34px;

    font-family: Poppins, Arial, sans-serif;
    font-size: 16px;
    color: #e8e9ed;
    text-decoration: none;

    &__active {
      color: #fdbf12;
    }

    &__special {
      color: #000;
      background-color: #fdbf12;
      padding: 8px 14px;
      margin-right: 0px;
      border-radius: 32px;
    }
  }

  &__lines {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__line {
    position: absolute;
    width: 1px;
    height: 100vh;
    height: -webkit-fill-available;
    background-color: rgba(127, 127, 127, 0.2);
    z-index: 1;

    &__first {
      left: 79px;
      @media (max-width: 1200px) {
        left: calc(6.6% - 1px);
      }
      @media (max-width: 400px) {
        left: 29px;
      }
    }
    &__last {
      right: 79px;
      @media (max-width: 1200px) {
        right: calc(6.6% - 1px);
      }
      @media (max-width: 400px) {
        right: 29px;
      }
    }
    &__moving::before {
      position: absolute;
      top: 0;
      left: -2px;
      content: url('./../assets/grid_patt.svg');
      display: block;
      width: 5px;
      height: 25px;
      opacity: 1;
      transition: top 2s ease-in;
    }
    &__moving:nth-of-type(2) {
      left: calc((100% - 160px) / 6 + 79px);

      @media (max-width: 1200px) {
        left: calc((100% - 13.2%) / 4 + 6.6%);
      }
      @media (max-width: 540px) {
        left: 50%;
      }

      &::before {
        transform: translateY(30vh);
        animation: linePatterTwo 20s ease-out infinite alternate;
      }
    }
    &__moving:nth-of-type(3) {
      left: calc((100% - 160px) / 6 * 2 + 79px);

      @media (max-width: 1200px) {
        left: calc((100% - 13.2%) / 4 * 2 + 6.6%);
      }
      @media (max-width: 540px) {
        display: none;
      }

      &::before {
        transform: translateY(80vh);
        animation: linePatterThree 27s ease-out infinite alternate;
      }
    }
    &__moving:nth-of-type(4) {
      left: calc((100% - 160px) / 6 * 3 + 79px);

      @media (max-width: 1200px) {
        left: calc((100% - 13.2%) / 4 * 3 + 6.6%);
      }
      @media (max-width: 540px) {
        display: none;
      }

      &::before {
        transform: translateY(10vh);
        animation: linePatterFour 30s ease-out infinite alternate;
      }
    }
    &__moving:nth-of-type(5) {
      left: calc((100% - 160px) / 6 * 4 + 79px);

      @media (max-width: 1200px) {
        display: none;
      }

      &::before {
        transform: translateY(50vh);
        animation: linePatterFive 25s ease-out infinite alternate;
      }
    }
    &__moving:nth-of-type(6) {
      left: calc((100% - 160px) / 6 * 5 + 79px);

      @media (max-width: 1200px) {
        display: none;
      }

      &::before {
        transform: translateY(35vh);
        animation: linePatterSix 22s ease-out infinite alternate;
      }
    }
  }

  &__buttons-trigger {
    position: absolute;
    top: calc(max(100px, 20vh));
    left: 0;
    width: 1px;
    height: 1px;

    @media (max-width: 850px) {
      top: -2px;
    }
  }

  &__menu-button {
    margin: 0px auto;
    width: 27px;
    height: 109px;
    cursor: pointer;

    @media (max-width: 650px) {
      height: 70px;
    }
  }

  &__email {
    position: relative;
    transform: rotate(-90deg);
    color: #fff;
    font-family: Poppins, Arial, sans-serif;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      color: #fdbf12;
    }

    a {
      color: inherit;
      transition: all 0.3s;
      text-decoration: none;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: -60px;
      display: inline-block;
      width: 0px;
      height: 140px;
      border-left: 1px solid;
      transform: rotate(-90deg);
      transition: all 0.3s;

      @media (max-height: 700px) {
        height: 40px;
        top: -10px;
      }

      @media (max-height: 480px) {
        display: none;
      }
    }
    &::before {
      right: -90px;

      @media (max-height: 700px) {
        right: -40px;
      }
    }
    &::after {
      left: -92px;

      @media (max-height: 700px) {
        left: -42px;
      }
    }
  }

  &__logo-button {
    cursor: pointer;
  }
}

@keyframes linePatterTwo {
  0% {
    transform: translateY(30vh);
  }
  5% {
    transform: translateY(30vh);
  }
  15% {
    transform: translateY(60vh);
  }
  45% {
    transform: translateY(60vh);
  }
  65% {
    transform: translateY(10vh);
  }
  85% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(30vh);
  }
}

@keyframes linePatterThree {
  0% {
    transform: translateY(80vh);
  }
  5% {
    transform: translateY(80vh);
  }
  15% {
    transform: translateY(20vh);
  }
  45% {
    transform: translateY(20vh);
  }
  65% {
    transform: translateY(40vh);
  }
  85% {
    transform: translateY(40vh);
  }
  100% {
    transform: translateY(80vh);
  }
}

@keyframes linePatterFour {
  0% {
    transform: translateY(10vh);
  }
  5% {
    transform: translateY(10vh);
  }
  15% {
    transform: translateY(70vh);
  }
  45% {
    transform: translateY(70vh);
  }
  65% {
    transform: translateY(90vh);
  }
  85% {
    transform: translateY(90vh);
  }
  100% {
    transform: translateY(10vh);
  }
}

@keyframes linePatterFive {
  0% {
    transform: translateY(50vh);
  }
  5% {
    transform: translateY(50vh);
  }
  15% {
    transform: translateY(65vh);
  }
  45% {
    transform: translateY(65vh);
  }
  65% {
    transform: translateY(15vh);
  }
  85% {
    transform: translateY(15vh);
  }
  100% {
    transform: translateY(50vh);
  }
}

@keyframes linePatterSix {
  0% {
    transform: translateY(35vh);
  }
  5% {
    transform: translateY(35vh);
  }
  15% {
    transform: translateY(50vh);
  }
  45% {
    transform: translateY(50vh);
  }
  65% {
    transform: translateY(7vh);
  }
  85% {
    transform: translateY(7vh);
  }
  100% {
    transform: translateY(35vh);
  }
}
