.get-in-touch {
  margin: 8rem 0 0;

  &__content {
    position: relative;
    z-index: 3;
    background-color: #0044cb;
    padding: 30px;
  }

  &__form {
    max-width: 800px;
    margin: 0 auto;

    p {
      text-align: center;
    }
  }

  &__columns {
    padding: 3rem 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  form {
    width: 60%;
  }

  &__contacts {
    width: 35%;
  }

  &__field,
  &__field__wide,
  &__contact {
    padding: 0 0 2rem 0;

    label {
      display: block;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
    }

    input,
    textarea,
    select {
      width: 100%;
      font: 600 20px/34px Poppins, sans-serif;
      color: #fff;
      background-color: transparent !important;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 1px;

      &:focus {
        outline: none;
        border-bottom: 2px solid #fcbe12;
        padding-bottom: 0;
      }

      &:invalid {
        outline: none;
        border-bottom: 2px solid #e23174;
        padding-bottom: 0;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }

      &:-webkit-autofill {
        color: #fff !important;
        background: #000 !important;

        /* Remove the standard browsers colors for the inputs when using autofill */
        -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
        transition: 'color 9999s ease-out, background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
        transition-delay: 9999s;
      }

      option {
        color: #000;
      }
    }

    .menu__social-link {
      margin-left: 0px;
      margin-right: 8px;
    }
  }
  &__button {
    color: #e8e9ed;
    background-color: transparent;
    padding: 8px 34px;
    margin: 0 0 3rem;
    border-radius: 32px;
    font-family: Poppins, Arial, sans-serif;
    font-size: 16px;
    text-decoration: none;
    border: 1px solid;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.2s ease-in;

    &:hover {
      color: #fff;
      opacity: 1;
    }
  }

  &__error,
  &__success {
    margin: -1rem 0 1rem;
    font-size: 14px;
    padding: 8px 16px;
  }

  &__error {
    color: #fff;
    background-color: #e23174;
  }
  &__success {
    color: #000;
    background-color: #1afb96;
  }

  &__contact-info,
  &__contact-info a {
    font: 600 20px/34px Poppins, sans-serif;
    color: #fff;
    text-decoration: none;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }

  #contact-email,
  #contact-email a {
    color: #fdbf12;
    font-size: 32px;
    line-height: 46px;
  }

  &__copy {
    margin: 2rem 0 1rem;
    font-size: 16px;

    a {
      margin: 0 0 1rem 0;
      float: right;
      color: white;
    }
  }

  @media (max-width: 1024px) {
    &__columns {
      flex-direction: column;
    }

    form,
    &__contacts {
      width: 100%;
    }
  }

  @media (max-width: 1024px) and (min-width: 640px) {
    &__field {
      display: inline-block;
      width: 48%;
      margin-right: 4%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
